import React from "react"
import { Box } from "theme-ui"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

// import app components

const DoubleImages = (props) => {
  const { image1, image2 } = props

  const processedImage1 = getImage(image1)
  const processedImage2 = getImage(image2)

  return (
    <Box
      sx={{
        display: ["flex", "grid"],
        flexDirection: "column",
        gridTemplateColumns: "1fr 1fr",
        gridTemplateRows: "1fr",
        gap: "10px 10px",
        my: "10px",
        ".gatsby-image-wrapper": {
          height: ["300px", "200px"],
        },
      }}
    >
      <Box
        sx={{
          position: "relative",
          gridColumnStart: 1,
          gridColumnEnd: 2,
          gridRowStart: 1,
          gridRowEnd: 2,
        }}
      >
        {image1 && <GatsbyImage image={processedImage1} alt={""} />}
      </Box>

      <Box
        sx={{
          position: "relative",
          gridColumnStart: 2,
          gridColumnEnd: 3,
          gridRowStart: 1,
          gridRowEnd: 2,
        }}
      >
        {image2 && <GatsbyImage image={processedImage2} alt={""} />}
      </Box>
    </Box>
  )
}

export default DoubleImages
