import React from "react"
import { graphql } from "gatsby"
import { Box } from "theme-ui"
import { GatsbyImage } from "gatsby-plugin-image"
import Embed from "react-embed"

// import app components
import Layout from "../../components/Layout"
import Edges from "../../components/Edges"
import TextImage from "../../components/textImage/TextImage"
import DoubleImages from "../../components/DoubleImages"
import Textarea from "../../components/Textarea"

const Template = (props) => {
  const {
    data: {
      wpPost: {
        title,
        acf: {
          content: {
            image,
            image1,
            image2,
            image3,
            image4,
            image5,
            text,
            text1,
            text2,
            text3,
            video,
          },
        },

        seo,
      },
    },
  } = props

  const sections = [
    { text, image, alignimage: "Left", backgroundcolor: "bgBlue" },
  ]

  return (
    <Layout {...props} seo={seo}>
      <TextImage sections={sections} headline={title} />

      <Box py={5}>
        <Edges size="cmd">
          <Box>{text1 && <Textarea content={text1} />}</Box>
          <Box
            sx={{
              position: "relative",
              pt: "60px",
              ".gatsby-image-wrapper": { maxHeight: 450 },
            }}
          >
            {image1 && <GatsbyImage image={image1} alt={""} />}
          </Box>
          <Box py={4}>{text2 && <Textarea content={text2} />}</Box>
          <DoubleImages image1={image2} image2={image3} />
          <Box py={4}>{text3 && <Textarea content={text3} />}</Box>
          <Box sx={{ iframe: { minHeight: [null, 500] } }}>
            {video && typeof window !== "undefined" && <Embed url={video} />}
          </Box>
          <DoubleImages image1={image4} image2={image5} />
        </Edges>
      </Box>
    </Layout>
  )
}

export const CollectionQuery = graphql`
  query PostDefault($id: String!) {
    wpPost(id: { eq: $id }) {
      id
      title
      categories {
        nodes {
          databaseId
          name
          uri
        }
      }
      seo {
        title
        metaDesc
        opengraphImage {
          sourceUrl
        }
      }
      acf {
        fieldGroupName
        content {
          flex {
            ... on WpPost_Acf_Content_Flex_Textimage {
              fieldGroupName
              sections {
                text
                backgroundcolor
                alignimage
                link {
                  target
                  title
                  url
                }
                image {
                  altText
                  localFile {
                    childImageSharp {
                      gatsbyImageData(
                        width: 600
                        placeholder: BLURRED
                        quality: 100
                      )
                    }
                  }
                }
              }
            }
          }
          fieldGroupName
          text
          text1
          text2
          text3
          video
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1920, placeholder: BLURRED, quality: 100)
              }
            }
          }
          image1 {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1920, placeholder: BLURRED, quality: 100)
              }
            }
          }
          image2 {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1920, placeholder: BLURRED, quality: 100)
              }
            }
          }
          image3 {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1920, placeholder: BLURRED, quality: 100)
              }
            }
          }
          image4 {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1920, placeholder: BLURRED, quality: 100)
              }
            }
          }
          image5 {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 1920, placeholder: BLURRED, quality: 100)
              }
            }
          }
        }
      }
    }
  }
`

export default Template
